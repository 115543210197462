const pages = [
  {
    path: '*',
    redirect: '/'
  },
  {
    path: '/',
    redirect: '/chat'
  },
  {
    path: '/chat',
    name: 'chat',
    component:() => import('@/pages/chat/index.vue')
  },
  {
    path: '/message',
    name: 'message',
    component:() => import('@/pages/message/index.vue')
  },
  {
    path: '/placeOrder',
    name: 'placeOrder',
    component: () => import('@/pages/placeOrder/index.vue')
  },
  {
    path: '/propertyManager',
    name: 'propertyManager',
    component: () => import('@/pages/propertyManager/index.vue')
  },
  {
    path: '/propertyManager/login',
    name: 'propertyManagerLogin',
    component: () => import('@/pages/propertyManager/login/index.vue')
  },
  {
    path: '/propertyManager/home',
    name: 'propertyManagerHome',
    component: () => import('@/pages/propertyManager/home/index.vue')
  },
  {
    path: '/propertyManager/orderList',
    name: 'propertyManagerOrderList',
    component: () => import('@/pages/propertyManager/orderList/index.vue')
  },{
    path: '/propertyManager/rule',
    name: 'propertyManagerRule',
    component: () => import('@/pages/propertyManager/rule/index.vue')
  }
]


export default pages;